import styles from '@styles/components/ItemRealEstate/BottomRealEstate.module.css';
import moment from 'moment';
import React from 'react';
import dynamic from 'next/dynamic';
const CalendarIcon = dynamic(() => import('@public/svg/calendarTime.svg'), {
  ssr: false,
});
type RenderTimeProps = {
  createdAt?: string,
};
export default function RenderTime({ createdAt }: RenderTimeProps) {
  return (
    <div className="flex flex-row items-center">
      <CalendarIcon width={11} height={11} className="mr-1" />
      <div className={styles.createdAtText}>
        {moment(createdAt).format('DD/MM/YYYY')}
      </div>
    </div>
  );
}
