import { convertFloatWithDot } from '@utils/helper';

export const usePrice = (item) => {
  const price = item?.price?.to || item?.price;
  return convertPrice(price);
};

export function convertPrice(price) {
  let pricePost = '',
    unitPrice = '';
  if (parseFloat(price) >= Math.pow(10, 9)) {
    pricePost = parseFloat(parseFloat(price / 1000000000).toFixed(1));
  }
  if (parseFloat(price) >= 1000000 && parseFloat(price) < Math.pow(10, 9)) {
    pricePost = parseFloat(parseFloat(price / 1000000).toFixed(1));
  }
  if (parseFloat(price) >= 100000 && parseFloat(price) < 1000000) {
    pricePost = parseFloat(parseFloat(price / 1000).toFixed(1));
  }
  if (parseFloat(price) >= Math.pow(10, 12)) {
    pricePost = parseFloat(parseFloat(price / 1000000000000).toFixed(1));
  }
  if (parseFloat(price) >= Math.pow(10, 15)) {
    pricePost = parseFloat(parseFloat(price / 1000000000000000).toFixed(1));
  }
  if (parseFloat(price) >= Math.pow(10, 18)) {
    pricePost = parseFloat(parseFloat(price / 1000000000000000000).toFixed(1));
  }
  // if (parseFloat(price) >= 0 && parseFloat(price) < 1000) {
  //   pricePost = 0;
  // }
  if (parseFloat(price) >= Math.pow(10, 9)) {
    unitPrice = 'Tỷ';
  }
  if (parseFloat(price) >= 1000000 && parseFloat(price) < Math.pow(10, 9)) {
    unitPrice = 'Triệu';
  }
  if (parseFloat(price) >= 1000 && parseFloat(price) < 1000000) {
    unitPrice = 'Nghìn';
  }
  if (parseFloat(price) >= 0 && parseFloat(price) < 1000) {
    unitPrice = 'Đồng';
  }
  if (parseFloat(price) >= Math.pow(10, 12)) {
    unitPrice = 'Ngàn tỷ';
  }
  if (parseFloat(price) >= Math.pow(10, 15)) {
    unitPrice = 'Triệu tỷ';
  }
  if (parseFloat(price) >= Math.pow(10, 18)) {
    unitPrice = 'Tỷ tỷ';
  }
  return { pricePost: convertFloatWithDot(pricePost), unitPrice };
}
