import React from 'react';
import styles from '@styles/components/ItemRealEstate/RealEstate.module.css';

type ItemDescriptionProps = {
  icon?: any,
  value?: string,
  wrapperClass?: string,
  iconClass?: string,
  valueClass?: string,
};
export default function ItemDescription({
  icon,
  value,
  wrapperClass,
  iconClass,
  valueClass,
}: ItemDescriptionProps) {
  const Icon = icon;
  return (
    <div className={`flex-row flex items-center ${wrapperClass}`}>
      {value !== 0 && value !== '0 tầng' ? (
        <>
          {icon && <Icon className={`${styles.iconQuickInfo} ${iconClass}`} />}
          <div className={`${styles.txtQuickInfo} ${valueClass}`}>{value}</div>
        </>
      ) : null}
    </div>
  );
}
